/* Project Section */
.projects-section {
  padding: 50px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.projects-title {
  font-size: 2.5rem;
  color: #1E90FF;
  margin-bottom: 40px;
}

/* Container for all project boxes */
.projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 30px; /* Spacing between projects */
  justify-items: center; /* Centers project boxes horizontally */
  max-width: 1200px;
  margin: 0 auto;
}

/* Individual project box */
.project-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  width: 100%;
  max-width: 300px; /* Set a max-width for each box */
}

.project-box:hover {
  transform: translateY(-10px); /* Lift up on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Project Image Container */
.project-image-container {
  width: 100%;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Project Image */
.project-image {
  width: 50%;
  height: 50%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}

/* Project Title */
.project-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #1E90FF;
}

/* Project Description */
.project-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 15px;
}
/* Button Container */
.project-buttons {
  display: flex;
  justify-content: center; /* Center the buttons */
  gap: 10px; /* Adjust the space between buttons (increase or decrease as needed) */
  margin-top: 10px;
}

/* Project Link/Button Styles */
.project-link {
  display: inline-block;
  padding: 8px 12px; /* Adjust padding for a more compact look */
  background-color: #1E90FF;
  color: white;
  border-radius: 4px; /* Slightly reduce the border-radius */
  font-size: 0.9rem; /* Adjust the font size for smaller text */
  text-decoration: none;
  transition: background-color 0.3s ease;
  max-width: 150px; /* Set a maximum width for buttons */
  width: fit-content; /* Adjust width to fit content */
  text-align: center; /* Center text for better alignment */
}

.project-link:hover {
  background-color: #0066cc;
}


/* Responsive Styles */
@media (max-width: 1024px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
  }
}

@media (max-width: 768px) {
  .projects-container {
    grid-template-columns: 1fr; /* 1 column on small screens */
  }

  .project-buttons {
    flex-direction: column; /* Stack buttons vertically */
    gap: 10px; /* Space between buttons on mobile */
    align-items: center;
  }

  .project-link {
    width: 100%; /* Make buttons full width on mobile */
    text-align: center; /* Center text for better alignment */
  }
}
