.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background-color: transparent;
  box-shadow: none;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.navbar.scrolled {
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-logo a {
  font-size: 24px;
  color: #b82424;
  text-decoration: none;
  font-weight: bold;
}

.nav-links {
  display: flex;
  justify-content: center;
  flex: 1;
  gap: 50px;
  margin-top: -10px;
}

.nav-links a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
}

.nav-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.nav-logo img {
height: 40px;
width: auto;
transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Animation for smooth fade-in/out */
.logo-visible {
opacity: 1;
transform: scale(1);
}

.logo-hidden {
opacity: 0;
transform: scale(0.8);
pointer-events: none;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 5px 0;
  transition: all 0.3s;
}

/* Media Query for Mobile Screens */
@media (max-width: 768px) {
  /* Center the logo on mobile */
  .nav-logo {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(25%);
  }

  .nav-links {
      display: none;
      position: absolute;
      top: 60px;
      right: 0;
      height: 100vh;
      width: 200px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.1);
  }

  .nav-links.open {
      display: flex;
  }

  .hamburger {
      display: block;
  }
}
