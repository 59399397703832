@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

#home {
  height: 100vh;
  background-color: rgb(19, 36, 83); /* Keep original background */
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(165, 162, 162); /* Original text color */
  text-align: left;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 40%; /* Keep this width for desktop */
  margin-left: 200px; /* Keep left alignment for desktop */
}

.name {
  font-family: 'Poppins', sans-serif;
  font-size: 3.5rem;
  margin: 0;
  color: rgb(255, 255, 255); /* Original white color for the name */
}

.interests {
  font-size: 1.5rem;
  margin: 10px 0;
  position: relative;
  color: rgb(190, 182, 182); /* Original color for interests */
  font-family: 'Poppins', sans-serif;
  opacity: 80%;
}

.animcol {
  color: gold; /* Color for animated text */
  font-family: 'Poppins', sans-serif;
  opacity: 100%;
}

.social-links {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.social-links a {
  color: rgb(170, 165, 165); /* Social link icon color */
}

.romell-photo {
  width: 200px;
  height: auto;
}

img {
  border-radius: 50%; /* Round image for Romell's photo */
}

.skills {
  align-items: flex-start;
  margin-right: 200px;
  background-color: rgba(59, 58, 58, 0.301); /* Original gray background */
  padding: 20px;
  border-radius: 10px;
  width: 10%;
}

.skills-title {
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.skills-list {
  list-style: none;
  padding: 0;
}

.skill-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.skill-logo {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

@media (max-width: 768px) {
  #home {
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center content */
    padding: 10px;
    text-align: center; /* Center text for mobile */
    justify-content: center; /* Center content vertically */
    height: auto; /* Allow height to adjust automatically */
    min-height: 100vh; /* Ensure minimum height for full-screen */
  }

  .content {
    width: 100%; /* Full width on mobile */
    margin-left: 0; /* Remove left margin for mobile */
    align-items: center; /* Center align content */
    margin-top: 20px; /* Add top margin to push it down */
  }

  .name {
    font-size: 2.5rem; /* Reduce font size for mobile */
  }

  .interests {
    font-size: 1.0rem; /* Smaller text on mobile */
    text-align: center; /* Center text */
  }

  .romell-photo {
    width: 120px; /* Smaller image on mobile */
    height: 120px;
    margin-bottom: 20px; /* Add space below photo */
  }

  /* Flex container for photo and skills */
  .photo-and-skills {
    display: flex; /* Use flexbox for horizontal alignment */
    flex-direction: row; /* Align items in a row */
    justify-content: center; /* Center the items */
    margin-top: 20px; /* Add space above */
    width: 100%; /* Full width */
    gap: 20px; /* Space between photo and skills */
    align-items: center; /* Align items vertically */
  }

  .skills {
    margin-left: 200px;
    width: 50%; /* Set to 70% of the parent width for a smaller box */
    padding: 10px; /* Add padding */
    border-radius: 8px; /* Keep the rounded corners */
    background-color: rgba(59, 58, 58, 0.3); /* Match the background */
     /*align-items: center; Center items in the skills box */
  }

  .skills-title {
    font-size: 1.5rem; /* Adjust title size */
    margin-bottom: 15px; /* Ensure there's space below the title */
  }

  .skills-list {
    list-style: none;
    padding: 0;
  }

  .skill-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center; /* Center items in the skill list */
  }

  .skill-logo {
    width: 30px; /* Smaller logos for mobile */
    margin-right: 5px; /* Less space to fit better */
  }

}
